import { all, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./reducer";
import { actions as actionsApp } from "../app/reducer";
import * as Realm from "realm-web";
import app, { mongo } from "../../utils/api";
import { actionUpdate, updateNodeData } from "@stackhouseos/flower-client";
import { saveAs } from "file-saver";
import axios from "axios";

const baseUrl = process.env.REACT_APP_GET_CANVAS;


function generateObjectMapped(data, structure) {
  return Object.entries(data.steps).reduce((acc, [key, parts]) => {
    return {
      ...acc,
      [key]: Object.entries(parts).map(([table, part], i) => ({
        //[table]: part,
        ...part,
        tableId: table,
        type: structure.steps[key][table].type,
        rangePoints: structure.steps[key][table].config?.rangePoints,
        rows: structure.steps[key][table].config?.rows || [],
        categoryId: structure.steps[key][table].category,
        title: structure.steps[key][table].title,
        category: structure.steps[key][table].category || "", // METTERE IL TITLE
      })),
    };
  }, {});
}


function* getCanvas({ payload }) {
  const { flowName } = payload;
  const [, , slug, group] = window.location.pathname.split("/");

  yield put(updateNodeData("canvas", "success", undefined));
  yield put(updateNodeData("canvas", "error.message", undefined));

  try {
    //const { data } = yield axios.get(`${baseUrl}?id=${id}`);
    const data = yield mongo().collection("canvas").findOne({ slug });
    if (!data) {
      throw new Error('missing-canvas')
    }

    const checkId = yield select((state) => state.canvas?.data?.slug);

    if (slug !== checkId) {
      yield put(actions.destroy());
    }

    yield put(
      actions.setCanvas(new Realm.BSON.EJSON.parse(JSON.stringify(data)))
    );

    const isLikert = data.isLikert ? data.isLikert : false;

    yield put(actionUpdate("canvas", "current.isLikert", isLikert));

    const hasSummaryPart1 = data.categories.part1.some(
      (step) => step?.summary?.enable
    );
    const hasSummaryPart2 = data.categories.part2.some(
      (step) => step?.summary?.enable
    );

    yield put(
      actionUpdate("canvas", "current.hasSummaryPart1", hasSummaryPart1)
    );
    yield put(
      actionUpdate("canvas", "current.hasSummaryPart2", hasSummaryPart2)
    );

    yield put(updateNodeData("canvas", "success", true));
  } catch (error) {
    console.log(
      "🚀 ~ file: saga.js ~ line 52 ~ function*getCanvas ~ error",
      error
    );
    yield put(updateNodeData("canvas", "error.message", error.message));
  } finally {
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* openTutorial({ payload }) {
  yield put(actionsApp.toggleModal());
  yield put({ type: "flower/next", payload: { flowName: payload.flowName } });
}

function* scrollRestoration({ payload }) {
  if (payload.flowName === 'canvas') {
    yield window.scrollTo(0, 0);
  }
}

function* saveKeywordsTag({ payload }) {
  const { keyword, part, id } = payload;

  const canvasId = yield select((state) => state.canvas.current.id);
  const data = yield select((state) => state.canvas.current);
  const orderIndex = data.steps[part][id].orderIndex

  const path = `${part}.${orderIndex}`

  try {
    const result = yield app.currentUser.functions.saveUserKeyword(canvasId, path, keyword);
  } catch (e) {
  } finally {
  }
}

function* saveCanvasData({ payload }) {
  const { flowName } = payload;

  const [, , , group] = window.location.pathname.split("/");

  const data = yield select((state) => state.canvas.data);
  const canvasId = yield select((state) => state.canvas.current.id);

  const structure = yield select((state) => state.canvas.current);

  const mappedSteps = generateObjectMapped(data, structure);

  const mappedData = {
    ...data,
    steps: mappedSteps,
  };

  try {
    const result = yield app.currentUser.functions.saveCanvas({
      canvasId,
      data: mappedData,
      group: canvasId !== group && group,
    });

    yield put(actionUpdate("canvas", "lastResultId", result.id));
    yield put(updateNodeData("canvas", "success", true));
  } catch (e) {
    yield put(updateNodeData("canvas", "error.message", e.message));
  } finally {
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* downloadPdf({ payload }) {
  const { flowName } = payload;

  const resultID = yield select((state) => state.canvas.lastResultId);
  const canvasId = yield select((state) => state.canvas.current.id);

  yield saveAs(
    `${process.env.REACT_APP_BACKEND_BASE_URI}/api/downloadpdf?id=${resultID}&idCanvas=${canvasId}`, 'report.pdf'
  );

  yield put({ type: "flower/prev", payload: { flowName } });
}

function* downloadSummaryPdf({ payload }) {
  const { part } = payload;

  const [, , , group] = window.location.pathname.split("/");

  const canvasId = yield select((state) => state.canvas.current.id);
  const data = yield select((state) => state.canvas.data);
  const structure = yield select((state) => state.canvas.current);

  const mappedSteps = generateObjectMapped(data, structure);

  const mappedData = {
    ...data[part],
    steps: mappedSteps,
  };

  try {
    const result = yield app.currentUser.functions.saveCanvasTemp({
      canvasId,
      data: mappedData,
      group: canvasId !== group && group,
    });

    yield put(actionUpdate("canvas", "temp.summary", { resultId: result.id, canvasId, part }));

    // yield saveAs(
    //   `http://192.168.1.207:3000/api/downloadsummarypdf?id=${result.id}&idCanvas=${canvasId}&part=${part}`, 'report.pdf'
    //   //`${process.env.REACT_APP_BACKEND_BASE_URI}/api/downloadpdf?id=${resultID}&idCanvas=${canvasId}&part=${part}`, 'report.pdf'
    // );

    // yield put(actionUpdate("canvas", "lastResultId", result.id));
    // yield put(updateNodeData("canvas", "success", true));
  } catch (e) {
    // yield put(updateNodeData("canvas", "error.message", e.message));
  } finally {
    // yield put({ type: "flower/next", payload: { flowName } });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest("GET_CANVAS", getCanvas),
    takeLatest("SHOW_TUTORIAL", openTutorial),
    takeLatest("flower/next", scrollRestoration),
    takeLatest("flower/prev", scrollRestoration),
    takeLatest("flower/replace", scrollRestoration),
    takeLatest("SAVE_KEYWORDSTAG", saveKeywordsTag),
    takeLatest("SAVE_CANVASDATA", saveCanvasData),
    takeLatest("DOWNLOAD_PDF", downloadPdf),
    takeLatest("DOWNLOAD_PDF_SUMMARY", downloadSummaryPdf),
  ]);
}
